import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';
import PortalVideoUpdateNew from '../Common/PortalVideoUpdateNew';

const SessionsVideosSectionNewUpdate = () => {
  const data = useStaticQuery(graphql`
    query SessionsVideosSectionNewUpdateQuery {
      prismicSraSessions {
        data {
          section_title
          section_description {
            richText
          }
          vimeo_videos {
            wistia_video_id
            video_title
            video_description {
              richText
            }
            session_date
          }
        }
      }
    }
  `);

  const doc = data.prismicSraSessions;
  const videos = doc.data.vimeo_videos;

  // Custom function to parse "Month Year" strings to Date objects
  const parseDate = dateString => {
    if (typeof dateString !== 'string' || !dateString.trim()) {
      console.log('Invalid dateString:', dateString);
      return null; // Return null if dateString is not a non-empty string
    }

    const parts = dateString.trim().split(' ');
    if (parts.length !== 2) {
      console.log('Invalid dateString format:', dateString);
      return null; // Return null if dateString format is incorrect
    }

    const month = parts[0];
    const year = parts[1];
    const monthsMap = {
      "January": 0, "February": 1, "March": 2, "April": 3,
      "May": 4, "June": 5, "July": 6, "August": 7,
      "September": 8, "October": 9, "November": 10, "December": 11
    };
    const monthIndex = monthsMap[month];
    if (monthIndex === undefined) {
      console.log('Invalid month:', month);
      return null; // Return null if month is invalid
    }

    return new Date(year, monthIndex, 1);
  };

  // Convert session_date strings to Date objects for sorting
  videos.forEach(video => {
    video.session_date = parseDate(video.session_date);
  });

  // Sort videos array based on session_date (newest to oldest)
  videos.sort((a, b) => b.session_date - a.session_date);

  return (
    <>
      <div className="w-full max-w-screen-lg mt-6">
        <h2 className='text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold'>{doc.data.section_title}</h2>
        {doc.data.section_description.richText ? (
          <div className="mt-6">
            <RichText render={doc.data.section_description.richText} htmlSerializer={htmlSerializer} />
          </div>
        ) : null}
      </div>

      <div className='w-full grid grid-cols-1 xl:grid-cols-2 gap-6 xl:gap-16 mt-8 xl:mt-16'>
        {videos.map(item => (
          <PortalVideoUpdateNew key={item.wistia_video_id} videoID={item.wistia_video_id} videoTitle={item.video_title}>
            <RichText render={item.video_description.richText} htmlSerializer={htmlSerializer} />
          </PortalVideoUpdateNew>
        ))}
      </div>
    </>
  );
};

export default SessionsVideosSectionNewUpdate;